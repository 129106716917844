import React from 'react';
import { motion } from 'framer-motion';
import { LeadForm } from '../forms/LeadForm';

export function Hero() {
  return (
    <div id="hero-section" className="relative min-h-screen flex items-center pt-20 md:pt-0">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80"
          alt="Gestion de patrimoine"
          className="w-full h-full object-cover brightness-50"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black via-black/85 to-black/70" />
      </div>

      <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-0">
        <div className="grid lg:grid-cols-2 gap-8 md:gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6 md:mb-8">
              <span className="text-white block mb-2">Construisez et</span>
              <span className="text-emerald-400">Préservez votre Patrimoine</span>
            </h1>
            
            <p className="text-base sm:text-lg md:text-xl text-gray-300 leading-relaxed mb-8 md:mb-10 max-w-2xl">
              Expertise en gestion de patrimoine, conseil en investissements et solutions personnalisées pour atteindre vos objectifs financiers.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white/10 backdrop-blur-md rounded-2xl p-4 sm:p-6 border border-white/20"
          >
            <LeadForm onClose={() => {}} />
          </motion.div>
        </div>
      </div>
    </div>
  );
}