import React from 'react';
import { Hero } from '../components/sections/Hero';
import { About } from '../components/sections/About';
import { Features } from '../components/sections/Features';
import { Services } from '../components/sections/Services';
import { WealthSimulator } from '../components/interactive/WealthSimulator';
import { PriceSimulator } from '../components/interactive/PriceSimulator';
import { Stats } from '../components/sections/Stats';
import { Investment } from '../components/sections/Investment';
import { Testimonials } from '../components/sections/Testimonials';
import { FAQ } from '../components/sections/FAQ';
import { Newsletter } from '../components/sections/Newsletter';

export function Home() {
  return (
    <>
      <Hero />
      <About />
      <Features />
      <section id="services">
        <Services />
      </section>
      <section id="solutions">
        <Investment />
      </section>
      <section id="simulateurs">
        <WealthSimulator />
        <PriceSimulator />
      </section>
      <Stats />
      <Testimonials />
      <FAQ />
      <Newsletter />
    </>
  );
}