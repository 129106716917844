import React from 'react';
import { LegalLayout } from './LegalLayout';

export function LegalNotice() {
  return (
    <LegalLayout title="Mentions Légales">
      <div className="space-y-6 text-gray-300">
        <h2 className="text-2xl font-bold text-white mt-8 mb-4">1. Informations légales</h2>
        <p>
          IFI Patrimoine
          <br />
          SAS au capital de 9 000€
          <br />
          SIREN : 889 929 337
          <br />
          RCS Montpellier
          <br />
          TVA : FR84889929337
          <br />
          Siège social : 8 rue du Fer à Cheval, 34430 Saint-Jean-de-Védas
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">2. Activité réglementée</h2>
        <p>
          Conseil en Investissements Financiers (CIF)
          <br />
          Membre de la CNCGP
          <br />
          Enregistré à l'ORIAS sous le numéro 20009124
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">3. Responsabilité</h2>
        <p>
          IFI Patrimoine s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées sur ce site, dont elle se réserve le droit de corriger le contenu à tout moment et sans préavis. Toutefois, IFI Patrimoine ne peut garantir l'exactitude, la précision ou l'exhaustivité des informations mises à disposition sur ce site.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">4. Propriété intellectuelle</h2>
        <p>
          L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">5. Hébergement</h2>
        <p>
          Ce site est hébergé par :
          <br />
          StackBlitz
          <br />
          San Francisco, CA 94107, United States
        </p>
      </div>
    </LegalLayout>
  );
}