import React from 'react';
import { motion } from 'framer-motion';
import { Card } from '../ui/Card';
import { Award, Shield, Users, Briefcase } from 'lucide-react';

const features = [
  {
    icon: <Award className="h-8 w-8" />,
    title: "Expertise Reconnue",
    description: "Plus de 15 ans d'expérience en gestion de patrimoine et conseil financier",
  },
  {
    icon: <Shield className="h-8 w-8" />,
    title: "Sécurité & Conformité",
    description: "Conseiller en Investissements Financiers (CIF) agréé par l'AMF",
  },
  {
    icon: <Users className="h-8 w-8" />,
    title: "Approche Personnalisée",
    description: "Solutions sur-mesure adaptées à vos objectifs patrimoniaux",
  },
  {
    icon: <Briefcase className="h-8 w-8" />,
    title: "Solutions Diversifiées",
    description: "Large gamme de solutions d'investissement et de stratégies patrimoniales",
  }
];

export function Features() {
  return (
    <section className="py-24 bg-gray-900">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-2xl mx-auto text-center mb-16"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Pourquoi choisir IFI Patrimoine ?
          </h2>
          <p className="text-gray-400 text-lg">
            Une expertise reconnue au service de votre patrimoine
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="h-full bg-gray-800 border border-gray-700">
                <div className="text-emerald-400 mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-white mb-3">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}