export const services = [
  {
    id: 1,
    title: "Gestion de Patrimoine",
    description: "Optimisation et structuration de votre patrimoine",
    features: [
      "Audit patrimonial complet",
      "Stratégie d'investissement",
      "Optimisation fiscale",
      "Transmission patrimoniale"
    ]
  },
  {
    id: 2,
    title: "Investissements Financiers",
    description: "Solutions d'investissement diversifiées",
    features: [
      "Marchés financiers",
      "Produits structurés",
      "Assurance-vie",
      "PER & PERIN"
    ]
  },
  {
    id: 3,
    title: "Immobilier",
    description: "Stratégies d'investissement immobilier",
    features: [
      "SCPI",
      "Immobilier direct",
      "Défiscalisation",
      "Crédit immobilier"
    ]
  }
];