import React from 'react';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { ArrowRight, Shield, Briefcase, LineChart } from 'lucide-react';

const investmentOptions = [
  {
    title: "Gestion Privée",
    description: "Solution personnalisée pour débuter",
    icon: Shield,
    features: [
      "Audit patrimonial complet",
      "Stratégie d'investissement sur-mesure",
      "Suivi trimestriel",
      "Accès à notre équipe dédiée"
    ]
  },
  {
    title: "Gestion Premium",
    description: "Pour une gestion patrimoniale optimale",
    icon: Briefcase,
    features: [
      "Stratégie patrimoniale globale",
      "Optimisation fiscale avancée",
      "Suivi mensuel personnalisé",
      "Accès prioritaire 24/7",
      "Solutions d'investissement exclusives"
    ]
  },
  {
    title: "Family Office",
    description: "Excellence et sur-mesure",
    icon: LineChart,
    features: [
      "Gouvernance familiale",
      "Ingénierie patrimoniale",
      "Consolidation multi-banques",
      "Conciergerie patrimoniale",
      "Club deals exclusifs"
    ]
  }
];

export function Investment() {
  const handleClick = () => {
    document.querySelector('#hero-section')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <section className="py-32 relative bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Nos Solutions d'Accompagnement
          </h2>
          <p className="text-lg text-gray-400 max-w-2xl mx-auto">
            Une approche sur-mesure adaptée à vos objectifs patrimoniaux
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {investmentOptions.map((option, index) => {
            const Icon = option.icon;
            return (
              <Card 
                key={index} 
                className="relative group flex flex-col h-full"
                hover={true}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity rounded-2xl" />
                <div className="relative flex-1 flex flex-col">
                  <div className="flex items-center gap-3 mb-6">
                    <Icon className="h-8 w-8 text-emerald-400" />
                    <h3 className="text-2xl font-bold text-white">{option.title}</h3>
                  </div>
                  
                  <p className="text-white/60 mb-8">{option.description}</p>
                  
                  <ul className="space-y-4 mb-8 flex-1">
                    {option.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start text-white">
                        <ArrowRight className="h-5 w-5 text-emerald-400 mr-2 flex-shrink-0 mt-0.5" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <Button onClick={handleClick} className="w-full mt-auto">
                    Prendre rendez-vous
                  </Button>
                </div>
              </Card>
            );
          })}
        </div>
      </div>
    </section>
  );
}