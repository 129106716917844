export const realEstateData = {
  regions: [
    {
      name: "Auvergne-Rhône-Alpes",
      averagePrice: 2890,
      trend: "+3.5%",
      cities: [
        { name: "Lyon", price: 5100 },
        { name: "Grenoble", price: 3200 },
        { name: "Clermont-Ferrand", price: 2400 }
      ]
    },
    {
      name: "Bourgogne-Franche-Comté",
      averagePrice: 1680,
      trend: "+1.8%",
      cities: [
        { name: "Dijon", price: 2500 },
        { name: "Besançon", price: 2100 },
        { name: "Mâcon", price: 1900 }
      ]
    },
    {
      name: "Bretagne",
      averagePrice: 2450,
      trend: "+4.7%",
      cities: [
        { name: "Rennes", price: 3800 },
        { name: "Brest", price: 2200 },
        { name: "Saint-Malo", price: 4100 }
      ]
    },
    {
      name: "Centre-Val de Loire",
      averagePrice: 1890,
      trend: "+1.5%",
      cities: [
        { name: "Orléans", price: 2400 },
        { name: "Tours", price: 2700 },
        { name: "Bourges", price: 1500 }
      ]
    },
    {
      name: "Corse",
      averagePrice: 3450,
      trend: "+2.9%",
      cities: [
        { name: "Ajaccio", price: 3900 },
        { name: "Bastia", price: 3200 },
        { name: "Porto-Vecchio", price: 4200 }
      ]
    },
    {
      name: "Grand Est",
      averagePrice: 1980,
      trend: "+1.2%",
      cities: [
        { name: "Strasbourg", price: 3400 },
        { name: "Reims", price: 2500 },
        { name: "Metz", price: 2200 }
      ]
    },
    {
      name: "Hauts-de-France",
      averagePrice: 1890,
      trend: "+1.7%",
      cities: [
        { name: "Lille", price: 3300 },
        { name: "Amiens", price: 2100 },
        { name: "Roubaix", price: 1800 }
      ]
    },
    {
      name: "Île-de-France",
      averagePrice: 6850,
      trend: "+4.2%",
      cities: [
        { name: "Paris", price: 10500 },
        { name: "Versailles", price: 7200 },
        { name: "Boulogne-Billancourt", price: 8900 }
      ]
    },
    {
      name: "Normandie",
      averagePrice: 2150,
      trend: "+2.4%",
      cities: [
        { name: "Rouen", price: 2800 },
        { name: "Caen", price: 2500 },
        { name: "Le Havre", price: 2200 }
      ]
    },
    {
      name: "Nouvelle-Aquitaine",
      averagePrice: 2380,
      trend: "+3.8%",
      cities: [
        { name: "Bordeaux", price: 4600 },
        { name: "La Rochelle", price: 4200 },
        { name: "Biarritz", price: 5800 }
      ]
    },
    {
      name: "Occitanie",
      averagePrice: 2800,
      trend: "+2.9%",
      cities: [
        { name: "Montpellier", price: 3500 },
        { name: "Toulouse", price: 3200 },
        { name: "Nîmes", price: 2100 }
      ]
    },
    {
      name: "Pays de la Loire",
      averagePrice: 2490,
      trend: "+3.6%",
      cities: [
        { name: "Nantes", price: 3900 },
        { name: "Angers", price: 2800 },
        { name: "La Baule", price: 5200 }
      ]
    },
    {
      name: "Provence-Alpes-Côte d'Azur",
      averagePrice: 4200,
      trend: "+3.8%",
      cities: [
        { name: "Nice", price: 4800 },
        { name: "Marseille", price: 3200 },
        { name: "Cannes", price: 6100 }
      ]
    }
  ]
};