import React from 'react';
import { cn } from '@/lib/utils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'ghost';
  children: React.ReactNode;
}

export function Button({ variant = 'primary', children, className, ...props }: ButtonProps) {
  return (
    <button
      className={cn(
        'group relative px-8 py-4 rounded-xl transition-all duration-300 overflow-hidden',
        variant === 'primary' && 'bg-gradient-to-r from-emerald-500 to-teal-500 text-white hover:scale-105',
        variant === 'secondary' && 'bg-white/10 text-white hover:bg-white/20',
        variant === 'ghost' && 'bg-transparent text-white hover:bg-white/10',
        className
      )}
      {...props}
    >
      <span className="relative z-10 flex items-center justify-center gap-2">{children}</span>
      {variant === 'primary' && (
        <div className="absolute inset-0 bg-gradient-to-r from-emerald-600 to-teal-600 opacity-0 group-hover:opacity-100 transition-opacity" />
      )}
    </button>
  );
}