import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { Home, TrendingUp, Map } from 'lucide-react';
import { realEstateData } from '@/data/realEstateData';

export function PriceSimulator() {
  const [selectedRegion, setSelectedRegion] = useState(realEstateData.regions[0]);
  const [surface, setSurface] = useState(50);

  const calculateEstimatedPrice = () => {
    return (surface * selectedRegion.averagePrice).toLocaleString();
  };

  const handleEstimationClick = () => {
    // Faire défiler jusqu'au formulaire en haut de la page
    document.querySelector('#hero-section')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto"
        >
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/10 to-transparent" />
            <div className="relative p-8">
              <h2 className="text-3xl font-bold text-white mb-8 flex items-center">
                <Home className="h-8 w-8 mr-3 text-emerald-400" />
                Simulateur de Prix Immobilier
              </h2>

              <div className="grid md:grid-cols-2 gap-8 mb-8">
                <div>
                  <label className="block text-white mb-2">Région</label>
                  <select
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
                    value={selectedRegion.name}
                    onChange={(e) => setSelectedRegion(
                      realEstateData.regions.find(r => r.name === e.target.value)!
                    )}
                  >
                    {realEstateData.regions.map((region) => (
                      <option key={region.name} value={region.name}>
                        {region.name} - {region.averagePrice}€/m²
                      </option>
                    ))}
                  </select>

                  <div className="mt-6">
                    <label className="block text-white mb-2">Surface (m²)</label>
                    <input
                      type="range"
                      min="20"
                      max="200"
                      value={surface}
                      onChange={(e) => setSurface(Number(e.target.value))}
                      className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="text-emerald-400 font-bold mt-2">
                      {surface} m²
                    </div>
                  </div>
                </div>

                <div className="bg-white/5 rounded-xl p-6">
                  <h3 className="text-xl font-bold text-white mb-4">Prix moyens à {selectedRegion.name}</h3>
                  <div className="space-y-4">
                    {selectedRegion.cities.map((city) => (
                      <div key={city.name} className="flex justify-between items-center">
                        <span className="text-white/80">{city.name}</span>
                        <span className="text-emerald-400 font-bold">{city.price}€/m²</span>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 pt-4 border-t border-white/10">
                    <div className="flex items-center justify-between">
                      <span className="text-white/80">Tendance</span>
                      <span className="text-emerald-400 font-bold flex items-center">
                        <TrendingUp className="h-4 w-4 mr-1" />
                        {selectedRegion.trend}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
                <div className="text-center">
                  <div className="text-gray-400 mb-2">Estimation pour {surface}m² à {selectedRegion.name}</div>
                  <div className="text-3xl font-bold text-emerald-400">
                    {calculateEstimatedPrice()}€
                  </div>
                </div>
              </div>

              <div className="mt-8 text-center">
                <Button onClick={handleEstimationClick}>
                  <Map className="h-5 w-5 mr-2" />
                  Obtenir une estimation détaillée
                </Button>
              </div>
            </div>
          </Card>
        </motion.div>
      </div>
    </section>
  );
}