import { useState } from 'react';

interface FormState {
  submitting: boolean;
  succeeded: boolean;
  errors: string[];
}

export function useFormspree(endpoint: string) {
  const [state, setState] = useState<FormState>({
    submitting: false,
    succeeded: false,
    errors: [],
  });

  const submit = async (data: Record<string, any>) => {
    setState(s => ({ ...s, submitting: true, errors: [] }));

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setState(s => ({ ...s, succeeded: true, submitting: false }));
        return true;
      } else {
        setState(s => ({ 
          ...s, 
          submitting: false,
          errors: ['Une erreur est survenue. Veuillez réessayer.']
        }));
        return false;
      }
    } catch (error) {
      setState(s => ({ 
        ...s, 
        submitting: false,
        errors: ['Une erreur de connexion est survenue. Veuillez réessayer.']
      }));
      return false;
    }
  };

  return { ...state, submit };
}