import React from 'react';
import { motion } from 'framer-motion';
import { Send, Mail } from 'lucide-react';
import { Button } from '../ui/Button';

export function Newsletter() {
  return (
    <section className="py-24 bg-gray-900">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-xl mx-auto text-center"
        >
          <div className="flex justify-center mb-6">
            <Mail className="h-12 w-12 text-emerald-400" />
          </div>
          
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Abonnez-vous à notre newsletter
          </h2>
          
          <p className="text-gray-400 mb-8">
            Recevez nos dernières actualités et conseils en gestion de patrimoine
          </p>
          
          <form className="space-y-4">
            <input
              type="email"
              placeholder="Votre adresse email"
              className="w-full px-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 transition"
            />
            
            <Button className="w-full">
              <span>S'abonner</span>
              <Send className="ml-2 h-5 w-5" />
            </Button>
          </form>

          <p className="text-sm text-gray-500 mt-4">
            En vous inscrivant, vous acceptez de recevoir nos communications. Vous pourrez vous désabonner à tout moment.
          </p>
        </motion.div>
      </div>
    </section>
  );
}