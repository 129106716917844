import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { Calculator, PieChart } from 'lucide-react';

export function WealthSimulator() {
  const [monthlyInvestment, setMonthlyInvestment] = useState(1000);
  const [initialCapital, setInitialCapital] = useState(50000);
  const [years, setYears] = useState(10);
  const [expectedReturn, setExpectedReturn] = useState(5);

  const calculateFutureValue = () => {
    const monthlyRate = expectedReturn / 100 / 12;
    const months = years * 12;
    const futureValue = initialCapital * Math.pow(1 + monthlyRate, months) +
      monthlyInvestment * ((Math.pow(1 + monthlyRate, months) - 1) / monthlyRate);
    return Math.round(futureValue);
  };

  const handlePersonalizedStudy = () => {
    document.querySelector('#hero-section')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto"
        >
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/10 to-transparent" />
            <div className="relative p-8">
              <h2 className="text-3xl font-bold text-white mb-8 flex items-center">
                <Calculator className="h-8 w-8 mr-3 text-emerald-400" />
                Simulateur Patrimonial
              </h2>

              <div className="grid md:grid-cols-2 gap-8 mb-8">
                <div className="space-y-6">
                  <div>
                    <label className="block text-white mb-2">Capital initial</label>
                    <input
                      type="range"
                      min="0"
                      max="500000"
                      step="10000"
                      value={initialCapital}
                      onChange={(e) => setInitialCapital(Number(e.target.value))}
                      className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="text-emerald-400 font-bold mt-2">
                      {initialCapital.toLocaleString()}€
                    </div>
                  </div>

                  <div>
                    <label className="block text-white mb-2">Investissement mensuel</label>
                    <input
                      type="range"
                      min="100"
                      max="5000"
                      step="100"
                      value={monthlyInvestment}
                      onChange={(e) => setMonthlyInvestment(Number(e.target.value))}
                      className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="text-emerald-400 font-bold mt-2">
                      {monthlyInvestment.toLocaleString()}€
                    </div>
                  </div>
                </div>

                <div className="space-y-6">
                  <div>
                    <label className="block text-white mb-2">Durée (années)</label>
                    <input
                      type="range"
                      min="1"
                      max="30"
                      value={years}
                      onChange={(e) => setYears(Number(e.target.value))}
                      className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="text-emerald-400 font-bold mt-2">
                      {years} ans
                    </div>
                  </div>

                  <div>
                    <label className="block text-white mb-2">Rendement annuel estimé (%)</label>
                    <input
                      type="range"
                      min="1"
                      max="12"
                      value={expectedReturn}
                      onChange={(e) => setExpectedReturn(Number(e.target.value))}
                      className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="text-emerald-400 font-bold mt-2">
                      {expectedReturn}%
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white/5 rounded-xl p-6 backdrop-blur-sm">
                <div className="grid md:grid-cols-3 gap-6">
                  <div className="text-center">
                    <div className="text-gray-400 mb-2">Capital investi</div>
                    <div className="text-2xl font-bold text-white">
                      {(initialCapital + monthlyInvestment * years * 12).toLocaleString()}€
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-gray-400 mb-2">Plus-value estimée</div>
                    <div className="text-2xl font-bold text-emerald-400">
                      {(calculateFutureValue() - (initialCapital + monthlyInvestment * years * 12)).toLocaleString()}€
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-gray-400 mb-2">Capital final estimé</div>
                    <div className="text-2xl font-bold text-white">
                      {calculateFutureValue().toLocaleString()}€
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 text-center">
                <Button onClick={handlePersonalizedStudy}>
                  <PieChart className="h-5 w-5 mr-2" />
                  Obtenir une étude personnalisée
                </Button>
              </div>
            </div>
          </Card>
        </motion.div>
      </div>
    </section>
  );
}