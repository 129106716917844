import React, { useState } from 'react';
import { X, ArrowRight, Check } from 'lucide-react';
import { Button } from '../ui/Button';
import { useFormspree } from '@/hooks/useFormspree';

interface LeadFormProps {
  onClose: () => void;
}

export function LeadForm({ onClose }: LeadFormProps) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    investmentGoal: '',
    savingsType: '',
    investmentRange: '',
    benefitsFrequency: '',
    contactTime: ''
  });

  const { submitting, succeeded, errors, submit } = useFormspree('https://formspree.io/f/xgvegjpw');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const success = await submit(formData);
    if (success) {
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (succeeded) {
    return (
      <div className="text-center py-8">
        <div className="flex justify-center mb-4">
          <div className="bg-emerald-400/20 rounded-full p-3">
            <Check className="h-8 w-8 text-emerald-400" />
          </div>
        </div>
        <h3 className="text-xl font-semibold text-white mb-2">
          Merci pour votre demande !
        </h3>
        <p className="text-gray-400">
          Nous vous contacterons très prochainement.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-white">Votre projet patrimonial</h3>
        <button
          type="button"
          onClick={onClose}
          className="text-white/60 hover:text-white transition-colors"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      {errors.length > 0 && (
        <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 text-red-400 text-sm">
          {errors.map((error, i) => (
            <p key={i}>{error}</p>
          ))}
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          name="firstName"
          placeholder="Prénom"
          value={formData.firstName}
          onChange={handleChange}
          className="bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white placeholder-white/50 focus:border-emerald-400 transition-colors"
          required
        />
        <input
          type="text"
          name="lastName"
          placeholder="Nom"
          value={formData.lastName}
          onChange={handleChange}
          className="bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white placeholder-white/50 focus:border-emerald-400 transition-colors"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <input
          type="tel"
          name="phone"
          placeholder="Téléphone"
          value={formData.phone}
          onChange={handleChange}
          className="bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white placeholder-white/50 focus:border-emerald-400 transition-colors"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white placeholder-white/50 focus:border-emerald-400 transition-colors"
          required
        />
      </div>

      <select
        name="investmentGoal"
        value={formData.investmentGoal}
        onChange={handleChange}
        className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white focus:border-emerald-400 transition-colors [&>option]:text-gray-900"
        required
      >
        <option value="">Quel est votre objectif de placement ?</option>
        <option value="retraite">Préparer ma retraite</option>
        <option value="patrimoine">Constituer un patrimoine</option>
        <option value="revenus">Générer des revenus complémentaires</option>
        <option value="fiscalite">Optimiser ma fiscalité</option>
      </select>

      <select
        name="savingsType"
        value={formData.savingsType}
        onChange={handleChange}
        className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white focus:border-emerald-400 transition-colors [&>option]:text-gray-900"
        required
      >
        <option value="">Quel type d'épargne recherchez-vous ?</option>
        <option value="securise">Épargne sécurisée</option>
        <option value="dynamique">Investissement dynamique</option>
        <option value="immobilier">Immobilier</option>
        <option value="diversifie">Portefeuille diversifié</option>
      </select>

      <select
        name="investmentRange"
        value={formData.investmentRange}
        onChange={handleChange}
        className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white focus:border-emerald-400 transition-colors [&>option]:text-gray-900"
        required
      >
        <option value="">Votre capital à investir se situe entre :</option>
        <option value="0-50k">0 - 50 000€</option>
        <option value="50k-100k">50 000€ - 100 000€</option>
        <option value="100k-250k">100 000€ - 250 000€</option>
        <option value="250k+">Plus de 250 000€</option>
      </select>

      <select
        name="benefitsFrequency"
        value={formData.benefitsFrequency}
        onChange={handleChange}
        className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white focus:border-emerald-400 transition-colors [&>option]:text-gray-900"
        required
      >
        <option value="">Désirez-vous recevoir vos bénéfices de manière :</option>
        <option value="mensuelle">Mensuelle</option>
        <option value="trimestrielle">Trimestrielle</option>
        <option value="annuelle">Annuelle</option>
        <option value="capitalisation">Capitalisation</option>
      </select>

      <select
        name="contactTime"
        value={formData.contactTime}
        onChange={handleChange}
        className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-2 text-white focus:border-emerald-400 transition-colors [&>option]:text-gray-900"
        required
      >
        <option value="">Je préfère être contacté entre :</option>
        <option value="9-12">9h - 12h</option>
        <option value="12-14">12h - 14h</option>
        <option value="14-17">14h - 17h</option>
        <option value="17-19">17h - 19h</option>
      </select>

      <Button type="submit" className="w-full" disabled={submitting}>
        {submitting ? (
          <span>Envoi en cours...</span>
        ) : (
          <>
            <span>Envoyer ma demande</span>
            <ArrowRight className="ml-2 h-5 w-5" />
          </>
        )}
      </Button>
    </form>
  );
}