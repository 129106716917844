import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import { Accordion } from '../ui/Accordion';

const faqItems = [
  {
    title: "Comment débuter la gestion de mon patrimoine ?",
    content: "La première étape consiste à réaliser un audit patrimonial complet pour établir un état des lieux de votre situation actuelle. Nous analysons vos objectifs, votre situation familiale et professionnelle, ainsi que votre capacité d'épargne pour élaborer une stratégie personnalisée."
  },
  {
    title: "Quels types d'investissements proposez-vous ?",
    content: "Nous proposons une gamme complète de solutions d'investissement : immobilier (SCPI, direct), placements financiers (assurance-vie, PER, compte-titres), solutions de défiscalisation et stratégies de transmission patrimoniale. Chaque solution est sélectionnée en fonction de vos objectifs."
  },
  {
    title: "Quelle est la durée d'investissement recommandée ?",
    content: "La durée d'investissement dépend de vos objectifs patrimoniaux. Pour optimiser le potentiel de rendement et limiter les risques, nous recommandons généralement un horizon d'investissement minimum de 5 ans. Certaines stratégies peuvent nécessiter un engagement plus long pour maximiser les avantages fiscaux."
  },
  {
    title: "Comment sont sécurisés mes investissements ?",
    content: "Nous travaillons exclusivement avec des établissements financiers agréés et régulés. En tant que Conseiller en Investissements Financiers (CIF), nous sommes soumis à des obligations strictes de conformité et de transparence. Vos investissements bénéficient des protections réglementaires en vigueur."
  },
  {
    title: "Quel est le montant minimum pour investir ?",
    content: "Le montant minimum varie selon les solutions d'investissement. Certains produits sont accessibles à partir de quelques centaines d'euros par mois, tandis que d'autres peuvent nécessiter un investissement initial plus important. Nous adaptons nos recommandations à votre capacité d'investissement."
  }
];

export function FAQ() {
  return (
    <section className="py-24 bg-gray-900">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto"
        >
          <div className="text-center mb-12">
            <div className="flex justify-center mb-6">
              <HelpCircle className="h-12 w-12 text-emerald-400" />
            </div>
            <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
              Questions fréquentes
            </h2>
            <p className="text-gray-400">
              Retrouvez les réponses aux questions les plus courantes sur la gestion de patrimoine
            </p>
          </div>

          <Accordion items={faqItems} />
        </motion.div>
      </div>
    </section>
  );
}