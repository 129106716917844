import React from 'react';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-white/80">
      <div className="container mx-auto px-4 py-8">
        <div className="text-sm text-white/60">
          <div className="flex flex-wrap justify-center gap-4 mb-4">
            <a href="/mentions-legales" className="hover:text-emerald-400 transition-colors">
              Mentions légales
            </a>
            <span className="text-white/20">|</span>
            <a href="/politique-confidentialite" className="hover:text-emerald-400 transition-colors">
              Politique de confidentialité
            </a>
            <span className="text-white/20">|</span>
            <a href="/conditions-utilisation" className="hover:text-emerald-400 transition-colors">
              CGU
            </a>
            <span className="text-white/20">|</span>
            <a href="https://www.orias.fr/home/showIntermediaire/889929337" target="_blank" rel="noopener noreferrer" className="hover:text-emerald-400 transition-colors">
              ORIAS: 20009124
            </a>
          </div>
          <p className="text-center">© {new Date().getFullYear()} IFI Patrimoine. Tous droits réservés.</p>
          <p className="text-center mt-2 max-w-3xl mx-auto">
            Conseil pour la gestion et les affaires, conseil en gestion de patrimoine, 
            conseil en investissements financiers, démarchage bancaire et financier, 
            courtage et intermédiation en assurance.
          </p>
        </div>
      </div>
    </footer>
  );
}