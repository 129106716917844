import React from 'react';
import { LegalLayout } from './LegalLayout';

export function PrivacyPolicy() {
  return (
    <LegalLayout title="Politique de Confidentialité">
      <div className="space-y-6 text-gray-300">
        <p>
          Dernière mise à jour : {new Date().toLocaleDateString()}
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">1. Collecte des données</h2>
        <p>
          IFI Patrimoine collecte les informations que vous nous fournissez directement, notamment :
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Informations d'identification (nom, prénom)</li>
          <li>Coordonnées (email, téléphone, adresse)</li>
          <li>Informations financières et patrimoniales</li>
          <li>Préférences d'investissement</li>
        </ul>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">2. Utilisation des données</h2>
        <p>
          Nous utilisons vos données personnelles pour :
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Fournir nos services de conseil en gestion de patrimoine</li>
          <li>Personnaliser votre expérience</li>
          <li>Communiquer avec vous concernant votre compte</li>
          <li>Respecter nos obligations légales et réglementaires</li>
        </ul>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">3. Protection des données</h2>
        <p>
          Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données contre tout accès, modification, divulgation ou destruction non autorisés.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">4. Vos droits</h2>
        <p>
          Conformément au RGPD, vous disposez des droits suivants :
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Droit d'accès à vos données</li>
          <li>Droit de rectification</li>
          <li>Droit à l'effacement</li>
          <li>Droit à la limitation du traitement</li>
          <li>Droit à la portabilité des données</li>
          <li>Droit d'opposition</li>
        </ul>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">5. Contact</h2>
        <p>
          Pour toute question concernant cette politique de confidentialité ou pour exercer vos droits, contactez-nous à :
          <br />
          Email : contact@ifi-patrimoine.fr
          <br />
          Adresse : 8 rue du Fer à Cheval, 34430 Saint-Jean-de-Védas
        </p>
      </div>
    </LegalLayout>
  );
}