import React from 'react';
import { cn } from '@/lib/utils';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  hover?: boolean;
}

export function Card({ children, className, hover = true }: CardProps) {
  return (
    <div
      className={cn(
        'relative bg-gradient-to-br from-white/10 to-white/5 rounded-2xl p-8 backdrop-blur-sm',
        hover && 'hover:-translate-y-2 hover:shadow-xl hover:shadow-emerald-500/10',
        'transition-all duration-300',
        className
      )}
    >
      {children}
    </div>
  );
}