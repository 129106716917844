import React from 'react';
import { motion } from 'framer-motion';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { ChevronRight, Shield, TrendingUp, Building2 } from 'lucide-react';
import { services } from '@/data/services';

const icons = {
  "Gestion de Patrimoine": Shield,
  "Investissements Financiers": TrendingUp,
  "Immobilier": Building2
};

export function Services() {
  const handleLearnMore = () => {
    document.querySelector('#hero-section')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-2xl mx-auto text-center mb-12 md:mb-16"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Nos Solutions Patrimoniales
          </h2>
          <p className="text-gray-400 text-base sm:text-lg">
            Une approche personnalisée pour répondre à vos objectifs
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 max-w-6xl mx-auto">
          {services.map((service, index) => {
            const Icon = icons[service.title as keyof typeof icons];
            return (
              <motion.div
                key={service.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex flex-col h-full"
              >
                <Card className="flex flex-col h-full p-6 sm:p-8">
                  <div className="flex-1">
                    <div className="text-emerald-400 mb-6">
                      <Icon className="h-10 w-10 sm:h-12 sm:w-12" />
                    </div>
                    
                    <h3 className="text-lg sm:text-xl font-bold text-white mb-4">{service.title}</h3>
                    <p className="text-gray-400 text-sm sm:text-base mb-6">{service.description}</p>
                    
                    <ul className="space-y-3 mb-8">
                      {service.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center text-white/80 text-sm sm:text-base">
                          <ChevronRight className="h-4 w-4 text-emerald-400 mr-2 flex-shrink-0" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <Button 
                    onClick={handleLearnMore}
                    className="mt-auto w-full"
                  >
                    En savoir plus
                  </Button>
                </Card>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
}