import React from 'react';
import { motion } from 'framer-motion';
import { Card } from '../ui/Card';
import { Award, Users, History, Target } from 'lucide-react';

const achievements = [
  {
    icon: <History className="h-8 w-8" />,
    title: "15+ années d'expertise",
    description: "Une expérience solide dans la gestion de patrimoine et le conseil financier"
  },
  {
    icon: <Users className="h-8 w-8" />,
    title: "500+ clients satisfaits",
    description: "Une relation de confiance établie avec nos clients à travers la France"
  },
  {
    icon: <Award className="h-8 w-8" />,
    title: "Certifications reconnues",
    description: "Des conseillers certifiés AMF et membres de la CNCGP"
  },
  {
    icon: <Target className="h-8 w-8" />,
    title: "Objectifs atteints",
    description: "98% de nos clients atteignent leurs objectifs patrimoniaux"
  }
];

export function About() {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold text-white mb-6">
              À propos d'IFI Patrimoine
            </h2>
            <p className="text-lg text-gray-400 max-w-3xl mx-auto">
              Depuis plus de 15 ans, nous accompagnons nos clients dans la réalisation de leurs objectifs patrimoniaux avec une approche personnalisée et des solutions innovantes.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {achievements.map((achievement, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Card className="text-center h-full">
                  <div className="text-emerald-400 mb-4 flex justify-center">
                    {achievement.icon}
                  </div>
                  <h3 className="text-xl font-bold text-white mb-3">
                    {achievement.title}
                  </h3>
                  <p className="text-gray-400">
                    {achievement.description}
                  </p>
                </Card>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="mt-16 bg-gradient-to-r from-emerald-500/10 to-teal-500/10 rounded-2xl p-8 backdrop-blur-sm"
          >
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <h3 className="text-2xl font-bold text-white mb-4">
                  Notre engagement
                </h3>
                <p className="text-gray-400 mb-6">
                  Nous nous engageons à fournir un service personnalisé et transparent, adapté aux besoins spécifiques de chaque client. Notre approche combine expertise technique et compréhension approfondie de vos objectifs.
                </p>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-center">
                    <div className="h-2 w-2 bg-emerald-400 rounded-full mr-3" />
                    Conseil indépendant et objectif
                  </li>
                  <li className="flex items-center">
                    <div className="h-2 w-2 bg-emerald-400 rounded-full mr-3" />
                    Suivi régulier et personnalisé
                  </li>
                  <li className="flex items-center">
                    <div className="h-2 w-2 bg-emerald-400 rounded-full mr-3" />
                    Solutions innovantes et adaptées
                  </li>
                </ul>
              </div>
              <div className="relative">
                <img
                  src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80"
                  alt="Notre équipe en action"
                  className="rounded-xl shadow-lg"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-xl" />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}