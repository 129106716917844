import React from 'react';
import { LegalLayout } from './LegalLayout';

export function TermsOfService() {
  return (
    <LegalLayout title="Conditions Générales d'Utilisation">
      <div className="space-y-6 text-gray-300">
        <p>
          Dernière mise à jour : {new Date().toLocaleDateString()}
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">1. Acceptation des conditions</h2>
        <p>
          En accédant et en utilisant ce site, vous acceptez d'être lié par les présentes conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">2. Services proposés</h2>
        <p>
          IFI Patrimoine propose des services de :
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Conseil en gestion de patrimoine</li>
          <li>Conseil en investissements financiers</li>
          <li>Courtage en assurance</li>
          <li>Conseil en investissement immobilier</li>
        </ul>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">3. Responsabilités</h2>
        <p>
          Les informations fournies sur ce site sont à titre informatif uniquement. Elles ne constituent en aucun cas un conseil personnalisé ou une offre de services. Toute décision d'investissement doit être prise après consultation d'un conseiller qualifié.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">4. Protection des données</h2>
        <p>
          L'utilisation de nos services est soumise à notre politique de confidentialité, qui décrit comment nous traitons vos données personnelles.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">5. Modification des conditions</h2>
        <p>
          IFI Patrimoine se réserve le droit de modifier ces conditions à tout moment. Les modifications prennent effet dès leur publication sur le site.
        </p>

        <h2 className="text-2xl font-bold text-white mt-8 mb-4">6. Loi applicable</h2>
        <p>
          Les présentes conditions sont régies par le droit français. Tout litige relatif à l'utilisation du site sera soumis à la compétence exclusive des tribunaux français.
        </p>
      </div>
    </LegalLayout>
  );
}