import React from 'react';
import { motion } from 'framer-motion';
import { Star, Quote } from 'lucide-react';
import { Card } from '../ui/Card';

const testimonials = [
  {
    name: "Jean-Pierre M.",
    role: "Chef d'entreprise",
    content: "L'expertise d'IFI Patrimoine a été déterminante dans la structuration de mon patrimoine professionnel et personnel. Leur approche sur-mesure et leur professionnalisme sont remarquables.",
    rating: 5,
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80"
  },
  {
    name: "Sophie L.",
    role: "Médecin libéral",
    content: "Je suis accompagnée depuis 5 ans et les résultats sont au rendez-vous. Leur expertise en défiscalisation m'a permis d'optimiser significativement ma situation patrimoniale.",
    rating: 5,
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80"
  },
  {
    name: "Marc D.",
    role: "Cadre dirigeant",
    content: "Un accompagnement personnalisé et des conseils avisés qui m'ont permis de préparer sereinement ma retraite. Je recommande vivement leurs services.",
    rating: 5,
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80"
  }
];

export function Testimonials() {
  return (
    <section className="py-24 bg-gray-900">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Ils nous font confiance
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Découvrez les témoignages de nos clients satisfaits de notre accompagnement patrimonial
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="h-full relative">
                <div className="absolute -top-6 left-1/2 -translate-x-1/2">
                  <div className="relative w-12 h-12 rounded-full overflow-hidden border-4 border-gray-900">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>

                <div className="pt-8">
                  <div className="flex justify-center mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star
                        key={i}
                        className="h-5 w-5 text-yellow-500 fill-yellow-500"
                      />
                    ))}
                  </div>

                  <Quote className="h-8 w-8 text-emerald-400/20 mx-auto mb-4" />

                  <blockquote className="text-gray-300 text-center mb-6">
                    "{testimonial.content}"
                  </blockquote>

                  <div className="text-center">
                    <div className="font-semibold text-white">{testimonial.name}</div>
                    <div className="text-sm text-emerald-400">{testimonial.role}</div>
                  </div>
                </div>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}